import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    height:100%;
    flex-direction: row;
`;

const Image = styled.img`
   border-radius: 200px;
   max-width: 150px;
`;

const PostItImage = styled.img`
   max-width: 100px;
   transform: translateX(-25);
   transform: translateY(25px);

`;


function SupportMeSection(){
    const path = "https://www.buymeacoffee.com/gmenji";
    

    return (
        <Container>
            <a href={path}>
                <Image src="/bmc.png" alt="image" />
            </a>
            <PostItImage src="/postit.png" alt="image" />
        </Container>
       
    );
}
export default SupportMeSection;