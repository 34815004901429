import logo from './logo.svg';
// import './App.css';

import Container from './components/Container';
import Header from './components/Header';
import PictureFrame from './components/PictureFrame';
import SupportMeSection from './components/SupportMeSecton';
import Title from './components/Title'

function App() {
  return (
    <div className="App">
      <Container>
        <Header/>
        <PictureFrame/>
        <SupportMeSection/>
      </Container>
    </div>
  );
}

export default App;
