import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: blueviolet;
    min-height: 900px;
`;


function Container(props){
    return(
        <StyledContainer>
            {props.children}
        </StyledContainer>
    )
}
export default Container