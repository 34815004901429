import styled from 'styled-components'
import React, { useEffect, useState  } from 'react'
import defaultImage from '../pup.jpg'

const StyledContainer = styled.div`
     display: flex;
     flex-direction: column;
     align-items: center;
`

const StyledImage = styled.img`
    min-width: 0;
    width:100%;
    max-width:900px;
`;

const StyledImageAnother = styled.img`
    min-width: 0;
    width:100%;
    max-width:100px;
    border-radius: 7px;
    border: 1px solid lightgray;
    margin-bottom: 10px;
    padding: 5px;
`;


function PictureFrame(){

    const url = 'https://dog.ceo/api/breeds/image/random';

    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
        // GET request using fetch inside useEffect React hook
        fetch(url)
        .then(response => response.json())
        .then(data => setImageUrl(data.message))
        .catch(err => setImageUrl({defaultImage}))
        

            
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

        return(
            <StyledContainer>
                <a href="/">
                    <StyledImageAnother src="/another.png" alt="image" />
                </a>
                <StyledImage src={imageUrl}/>
            </StyledContainer>
        
        );
    
}


export default PictureFrame