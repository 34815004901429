import React, {Component} from 'react';

class Title extends Component{
    state = {}
    render(){
        return (<h1>TITLE</h1>)
    }


}

export default Title;