import styled from 'styled-components'
import React from 'react'


const StyledHeader = styled.div`
    display: flex;
    width:100%;
    justify-content: space-between;
`;

const StyledText = styled.h1`
    color : white;
    text-align: center;

`;

const Image = styled.img`
   width: 120px;
`;

const CartImage = styled.img`
   width: 25px;
   align-self: flex-start;

`;

function Header(){

    const path = "https://www.redbubble.com/i/long-sleeve-t-shirt/Sorry-I-m-Tall-by-gmenji/115271507.H0OIO";

    return(
        <StyledHeader>
            <Image src="/Logo.png" alt="image" />
            <a href={path}>
            <CartImage src="/shoppingcart.png" alt="image" />
            </a>
            
        </StyledHeader>
    )
}


export default Header